import React, { FC, useState, useEffect, ReactNode } from 'react';
import { FieldBannerInterface } from '../../../interfaces/frontpage';
import {
    SliderContainer,
    Slide,
    Bullet,
    Bullets,
    SlideText,
    SlideHeader,
    SlideArrowWrapper,
    SlideArrow,
    SlideLink,
    LeftArrowWrapper,
    RightArrowWrapper,
    LeftArrow,
    RightArrow,
} from './AutoPlaySliderWithDotsStyle';
import { urlForImage } from '../../../utils/urlForImage';
import { linkHelper } from '../../../utils/linkHelper';
import { useTranslation } from 'gatsby-plugin-react-i18next';

interface AutoPlaySliderWithDotsInterface {
    sliderData: FieldBannerInterface[];
}

const AutoPlaySliderWithDots: FC<AutoPlaySliderWithDotsInterface> = ({ sliderData }) => {
    const [activePhotoIndex, setActivePhotoIndex] = useState(0);
    const [resetTimer, setResetTimer] = useState(false);
    const {
        i18n: { language },
    } = useTranslation();
    const numberOfPhotos = sliderData?.length;
    const INTERVAL_TIME = 6000;
    let interval;

    const nextSlide = (): void => {
        setResetTimer((resetTimer) => !resetTimer);
        activePhotoIndex < numberOfPhotos - 1
            ? setActivePhotoIndex((activePhotoIndex) => ++activePhotoIndex)
            : setActivePhotoIndex(0);
    };

    const previousSlide = (): void => {
        setResetTimer((resetTimer) => !resetTimer);
        activePhotoIndex === 0
            ? setActivePhotoIndex(numberOfPhotos - 1)
            : setActivePhotoIndex((activePhotoIndex) => --activePhotoIndex);
    };

    const displaySlider = (): ReactNode =>
        sliderData
            ?.filter((sliderObject) => sliderObject.field_media_image?.length > 0)
            ?.map((sliderObject: FieldBannerInterface, index: number) => (
                <Slide
                    key={linkHelper(sliderObject.field_banner_link.uri, language)}
                    position={index < activePhotoIndex}
                    isActive={index === activePhotoIndex}
                    backgroundUri={urlForImage(sliderObject.field_media_image)}
                >
                    <SlideText isActive={index === activePhotoIndex}>
                        {sliderObject.field_text?.length > 0 && (
                            <SlideHeader dangerouslySetInnerHTML={{ __html: sliderObject.field_text }} />
                        )}
                        {sliderObject.field_banner_link.uri?.length > 0 &&
                            sliderObject.field_banner_link.text?.length > 0 && (
                                <div>
                                    <SlideLink to={linkHelper(sliderObject.field_banner_link.uri, language)}>
                                        <SlideArrowWrapper>
                                            <SlideArrow />
                                        </SlideArrowWrapper>
                                        {sliderObject.field_banner_link.text}
                                    </SlideLink>
                                </div>
                            )}
                    </SlideText>
                </Slide>
            ));

    useEffect(() => {
        interval = setInterval(() => {
            setActivePhotoIndex((activePhotoIndex) => ++activePhotoIndex);
        }, INTERVAL_TIME);
        return () => clearInterval(interval);
    }, [resetTimer]);

    useEffect(() => {
        if (activePhotoIndex >= numberOfPhotos) {
            setActivePhotoIndex(0);
        }
    }, [activePhotoIndex]);

    const changePhoto = (index: number): void => {
        setResetTimer((resetTimer) => !resetTimer);
        setActivePhotoIndex(index);
    };

    const displayBullets = (): ReactNode =>
        sliderData?.map((sliderObject: FieldBannerInterface, index: number) => (
            <Bullet
                key={sliderObject.field_banner_link.uri}
                onClick={() => changePhoto(index)}
                isActive={index === activePhotoIndex}
            />
        ));

    return (
        <SliderContainer>
            {displaySlider()}
            <Bullets>{displayBullets()}</Bullets>
            <LeftArrowWrapper onClick={nextSlide}>
                <LeftArrow />
            </LeftArrowWrapper>
            <RightArrowWrapper onClick={previousSlide}>
                <RightArrow />
            </RightArrowWrapper>
        </SliderContainer>
    );
};

export default AutoPlaySliderWithDots;
