import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactNode } from 'react';
import { AllNewsButton, NewsCard, NewsDescription, NewsImage, NewsWrapper, SectionContainer } from './NewsSectionStyle';
import { SectionHeadline } from '../../../styles/Common';
import { urlForImage } from '../../../utils/urlForImage';

const NewsSection = ({ news, allNewsLink, newsTitle }) => {
    const { t } = useTranslation();
    const { language } = useI18next();

    const displayNews = (): ReactNode =>
        news
            ?.filter((item: any, index: number) => index < 3 && item?.field_image?.length > 0)
            ?.map((item: any, index: number) => (
                <NewsCard key={index} to={item?.url ? `/${language}${item.url}` : '#'}>
                    <NewsImage
                        src={urlForImage(item?.field_image[0]?.field_media_image)}
                        alt={item?.field_image?.length > 0 ? item?.field_image[0]?.alt : 'news img'}
                    />
                    <NewsDescription>{item?.title}</NewsDescription>
                </NewsCard>
            ));

    return (
        <SectionContainer>
            <SectionHeadline>{newsTitle.length > 0 ? newsTitle : t('news')}</SectionHeadline>
            <NewsWrapper>{displayNews()}</NewsWrapper>
            {allNewsLink?.uri?.length > 0 && (
                <AllNewsButton to={`/${language}${allNewsLink.uri}`}>
                    {allNewsLink?.text?.length > 0 ? allNewsLink.text : t('allNews')}
                </AllNewsButton>
            )}
        </SectionContainer>
    );
};

export default NewsSection;
