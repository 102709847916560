import React from 'react';
import EventsSection from './EventsSection/EventsSection';
import { Container } from './FrontpageNewsStyle';
import NewsSection from './NewsSection/NewsSection';

const FrontpageNews = ({ news, events, allNewsLink, allEventsLink, newsTitle, eventsTitle }) => (
    <Container>
        {news.length && <NewsSection news={news} allNewsLink={allNewsLink} newsTitle={newsTitle} />}
        {events.length && <EventsSection events={events} allEventsLink={allEventsLink} eventsTitle={eventsTitle} />}
    </Container>
);

export default FrontpageNews;
